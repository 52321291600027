<template>

</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>